import { createI18n } from 'vue-i18n'

const enLocaleModules = import.meta.globEager('@tenant/**/**/locales/en.js')
const deLocaleModules = import.meta.globEager('@tenant/**/**/locales/de.js')
const elLocaleModules = import.meta.globEager('@tenant/**/**/locales/el.js')

const getLocales = (localeModules) => {
    let locales = {}
    for (const path in localeModules) {
        locales = { ...locales, ...localeModules[path].default }
    }

    return locales
}

const en = getLocales(enLocaleModules)
const de = getLocales(deLocaleModules)
const el = getLocales(elLocaleModules)

export default createI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en,
        de,
        el,
    },
})
